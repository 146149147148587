<template>
  <div class="subscriptions">
    <!-- alertMsg -->
    <v-alert :type="type" v-if="type">
      {{ alertMsg }}
    </v-alert>

    <!-- delete dialog box -->
    <v-dialog v-model="deleteDialog" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Confirmation
        </v-card-title>

        <v-card-text>
          <br />
          Are you sure you want to delete this Subscription plan:
          <b>{{ delSubscription.name }}</b
          >?
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="doDelSubscription()"> Yes </v-btn>
          <v-btn color="primary" text @click="deleteDialog = false"> No </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- add car dialog box -->
    <v-dialog v-model="addDialog" max-width="60%">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Add Subscription plan
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="addSubscription.name"
                  label="Name"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="addSubscription.code"
                  label="Code"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="addSubscription.listingLimit"
                  label="Listing Limit"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="addSubscription.monthlyPrice"
                  label="Monthly Price"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="addSubscription.offerPrice"
                  label="Offer Price"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="addSubscription.hostingFee"
                  label="Hosting Fee"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text color="primary" @click="doAddSubscription()"
            >Submit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- edit dialog box -->
    <v-dialog v-model="editDialog" max-width="60%">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Edit Subscription
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="editSubscription.name"
                  label="Name"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="editSubscription.code"
                  label="Code"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="editSubscription.listingLimit"
                  label="Listing Limit"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="editSubscription.monthlyPrice"
                  label="Monthly Price"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="editSubscription.offerPrice"
                  label="Offer Price"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="editSubscription.hostingFee"
                  label="Hosting Fee"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="editSubscription.biddingFeePercent"
                  label="Bidding Fee (In Percentage)"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text color="primary" @click="doEditSubscription()"
            >Submit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- search box -->
    <v-row>
      <v-col cols="12" sm="6" md="3">
        <v-text-field
          @keyup.enter.native="searchType()"
          v-model="nameSearch"
          label="ʕ•́ᴥ•̀ʔっ♡¯\_(ツ)_/¯"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-btn
          @click="searchType()"
          @keyup.enter.native="searchType()"
          large
          elevation
          color="primary"
        >
          Search
        </v-btn>
      </v-col>
    </v-row>

    <!-- floating button [for add] -->
    <v-btn
      fab
      absolute
      class="plusButton"
      right
      bottom
      color="red accent-2"
      @click="addDialog = !addDialog"
    >
      <v-icon color="#fff">mdi-plus</v-icon>
    </v-btn>

    <!-- pagination table -->
    <v-data-table
      :headers="headers"
      :items="subscriptions"
      :page.sync="pagination.page"
      :items-per-page="pagination.limit"
      hide-default-footer
      class="elevation-1"
    >
      <template v-slot:item="row">
        <tr>
          <td>{{ row.item.id }}</td>
          <td>{{ row.item.name }}</td>
          <td>{{ row.item.code }}</td>
          <td>{{ row.item.listingLimit }}</td>
          <td>{{ row.item.offerPrice }}</td>
          <td>{{ row.item.monthlyPrice }}</td>
          <td>{{ row.item.hostingFee }}</td>
          <td>{{ row.item.biddingFeePercent }}</td>

          <!-- tbl row edit btn -->
          <td>
            <v-btn
              class="mx-2"
              fab
              dark
              small
              color="primary"
              @click="editButtonClick(row.item)"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </td>
          <!-- tbl row del btn -->
          <td>
            <v-btn
              class="mx-2"
              fab
              dark
              small
              color="red"
              @click="delButtonClick(row.item)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-pagination
      v-model="pagination.page"
      :circle="true"
      :disabled="false"
      :length="pagination.noOfPage"
      :page="pagination.page"
      update:page
      :total-visible="10"
      @input="paginationChangeHandler"
    ></v-pagination>
  </div>
</template>
<script>
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

import postAxios from "../../../services/axios-post";
// import putAxios from "../../../services/axios-put";
import patchAxios from "../../../services/axios-patch";
import getAxios from "../../../services/axios-get";
import deleteAxios from "../../../services/axios-delete";

Vue.use(VueAxios, axios);

export default {
  data() {
    return {
      type: null,
      alertMsg: null,
      pageCount: 0,
      pagination: {
        limit: 10,
        noOfPage: 1,
        page: 1,
      },
      addSubscription: {
        code: null,
        name: null,
        listingLimit: null,
        offerPrice: null,
        monthlyPrice: null,
        hostingFee: null,
      },
      editSubscription: {
        code: null,
        id: "",
        name: "",
        listingLimit: "",
        offerPrice: "",
        monthlyPrice: "",
        hostingFee: "",
        biddingFeePercent: "",
      },
      delSubscription: {
        id: "",
        name: "",
      },
      nameSearch: "",
      total: 0,
      addDialog: false,
      editDialog: false,
      deleteDialog: false,
      token: "",
      headers: [
        { text: "Id", value: "id" },
        { text: "Name", value: "name" },
        { text: "Code", value: "code" },
        { text: "Listing limit", value: "listingLimit" },
        { text: "Offer Price", value: "offerPrice" },
        { text: "Monthly Price", value: "monthlyPrice" },
        { text: "Hosting Fee", value: "hostingFee" },
        { text: "Bidding Fee(%)", value: "biddingFeePercent" },
        { text: "Edit" },
        { text: "Delete" },
      ],
      subscriptions: [],
    };
  },
  mounted() {
    this.fetchCredentials();
    this.fetchSubscription();
  },
  methods: {
    paginationChangeHandler(pagination) {
      this.pagination.page = pagination;
      this.fetchSubscription();
    },
    delButtonClick(item) {
      this.delSubscription.id = item.id;
      this.delSubscription.name = item.name;
      this.deleteDialog = true;
    },
    editButtonClick(item) {
      this.editSubscription.id = item.id;
      this.editSubscription.code = item.code;
      this.editSubscription.name = item.name;
      this.editSubscription.listingLimit = item.listingLimit;
      this.editSubscription.offerPrice = item.offerPrice;
      this.editSubscription.monthlyPrice = item.monthlyPrice;
      this.editSubscription.hostingFee = item.hostingFee;
      this.editSubscription.biddingFeePercent = item.biddingFeePercent;
      this.editDialog = true;
    },
    resetAdd() {
      this.addSubscription.code = null;
      this.addSubscription.name = null;
      this.addSubscription.listingLimit = null;
      this.addSubscription.offerPrice = null;
      this.addSubscription.monthlyPrice = null;
      this.addSubscription.hostingFee = null;
    },
    resetEdit() {
      this.editSubscription.id = null;
      this.editSubscription.code = null;
      this.editSubscription.name = null;
      this.editSubscription.listingLimit = null;
      this.editSubscription.offerPrice = null;
      this.editSubscription.monthlyPrice = null;
      this.editSubscription.hostingFee = null;
      this.editSubscription.biddingFeePercent = null;
    },
    doDelSubscription() {
      const self = this;
      deleteAxios(
        `${process.env.VUE_APP_SERVER_URL}/api/v2/dealer-subscription/${self.delSubscription.id}`,
        self.token
      ).then(res => {
        if (res.data) {
          self.deleteDialog = false;
          self.fetchSubscription();
          self.$message({
            message: "Subscription Deleted Successfully!",
            type: "success",
          });
        }
      });
    },
    async doEditSubscription() {
      const self = this;
      try {
        patchAxios(
          `${process.env.VUE_APP_SERVER_URL}/api/v2/dealer-subscription/${this.editSubscription.id}`,
          this.editSubscription,
          self.token
        ).then(res => {
          if (res.data) {
            self.editDialog = false;
            self.fetchSubscription();
            self.resetEdit();
            self.showAlert("success", "Subscription Edited Successfully!");
          }
        });
      } catch (e) {
        self.showAlert("error", e);
        console.error(e);
      }
    },
    async doAddSubscription() {
      const self = this;
      try {
        console.log(this.addSubscription);
        postAxios(
          `${process.env.VUE_APP_SERVER_URL}/api/v2/dealer-subscription`,
          this.addSubscription,
          self.token
        ).then(res => {
          if (res.data) {
            self.addDialog = false;
            self.fetchSubscription();
            self.resetAdd();
            self.showAlert("success", "New Subscription Added!");
          }
        });
      } catch (e) {
        console.error(e);
      }
    },
    searchType() {
      this.fetchSubscription();
    },
    handlePageChange(newPage) {
      this.pagination.page = newPage;
      this.fetchCars();
    },
    handlePageSizeChange(newPageSize) {
      this.pagination.limit = newPageSize;
      const newPage = this.total / newPageSize;
      if (this.pagination.page > newPage) {
        this.pagination.page = Math.ceil(newPage);
      }
      this.fetchCars();
    },
    fetchSubscription() {
      const self = this;
      const params = {
        page: self.pagination.page - 1,
        size: self.pagination.limit,
        searchContent: self.nameSearch,
      };
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/api/v2/dealer-subscription`,
        params,
        self.token
      )
        .then(res => {
          console.log(res);
          if (res.data) {
            self.subscriptions = res.data.items;
            self.pagination.noOfPage = res.data.pageNumber;
          }
        })
        .catch(function(error) {
          self.showAlert("error", error);
        });
    },
    fetchCredentials() {
      var self = this;
      const token = localStorage.getItem("jwt");
      if (token) {
        self.token = token;
      } else {
        self.showAlert("error", "unauthorized..");
      }
    },
    showAlert(type, message) {
      this.type = type;
      this.alertMsg = message;
      let timer = this.showAlert.timer;
      if (timer) {
        clearTimeout(timer);
      }
      this.showAlert.timer = setTimeout(() => {
        this.type = null;
      }, 3000);
      let t = this.showAlert.t;
      if (t) {
        clearInterval(t);
      }
    },
  },
};
</script>
<style>
.plusButton {
  margin: 50px;
}
</style>
